import React from 'react'

import Zoom from 'react-reveal/Zoom'
export const Video = () => {
    return(
        <Zoom>
            <div className="px-8">
            <iframe title="The Green Investment Club" src="https://player.vimeo.com/video/500325493" className="mx-auto sm:min-h-70  rounded-xl sm:w-8/12 w-full sm:h-96" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
        </Zoom>
    )
}