import React from 'react'
import { Button } from '../../utils'
import Story from "./story"

import {graphql, useStaticQuery, Link} from 'gatsby'



export const Stories = () => {
    
    const testimonials = useStaticQuery(graphql `
    query {
        allContentfulTestimonial(limit: 3) {
          edges {
            node {
              color
              company
              name
              role
              testimony {
                testimony
              }
            }
          }
        }
      }
    `)

    const allTestimonial =  testimonials.allContentfulTestimonial.edges.map(
        (testimonial, i) => <Story key={i} content={testimonial.node.testimony.testimony}       
    name = {testimonial.node.name}
    role = {testimonial.node.role+", "+testimonial.node.company}
    color = {`bg-story-`+testimonial.node.color}
    />)
    return (
        <section className="max-w-screen-lg m-auto mb-32">
            <h2 className="sm:text-center text-left mb-12 sm:w-full w-10/12 px-12 sm:px-0 ">What is it like to be a part of TGIC?</h2>
              
                <div className="flex items-center flex-wrap overflow-hidden">
                      {allTestimonial}
                </div>
        
            
            <div className="text-center">
                <Link to="/member-stories"><Button text="More Success Stories" /></Link>
            </div>
        </section>
    )
}
