import React from 'react'

import Fade from 'react-reveal/Fade'

const Story = ({content, name, role, color}) => {
    return(
            <Fade big >
        <article className={(color)+` sm:w-4/12 sm:bg-arc-pc bg-arc sm:bg-left-top bg-top-sm w-11/12 sm:p-8 p-12 bg-no-repeat `}>
            {/* { console.log(typeof(color))} */}
            <content>
                <p className="mb-0">{content}</p>
            </content>
            <footer className="mt-6">
                <span className="font-bold">{name}</span><br/>
                {role}
            </footer>
        </article>
            </Fade>
    )
}

export default Story