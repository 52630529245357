import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Intro,
  Milestones,
  Offerings,
  Stories,
  Subfooter,
  Features,
  Video,
} from "../components/home"
import TGICReport from "../components/home/report"

const IndexPage = () => (
  <Layout home={true}>
    <SEO title="Home" />
    <Intro />
    <Milestones />
    <TGICReport />
    <Offerings />
    <Stories />
    <Video />
    <Features />
    <Subfooter />
  </Layout>
)

export default IndexPage
