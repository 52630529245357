import React from "react"
import ReportMockup from "../../../images/mockups/TGIC Report cover mockup.png"
import { useStaticQuery, graphql } from "gatsby"
import { Button, ListItem } from "../../utils"

const TGICReport = () => {
  const reportQuery = useStaticQuery(graphql`
    {
      allContentfulDocuments(filter: { name: { eq: "TGIC_Report" } }) {
        edges {
          node {
            name
            file {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const report_document = reportQuery.allContentfulDocuments.edges
  return (
    <section className="bg-torq-100 sm:py-28 py-16 sm:mb-32 mb-16 sm:min-h-screen sm:px-0 px-10">
      <section className="flex md:flex-row flex-col max-w-screen-lg mx-auto">
        <div className="md:w-4/12">
          <img
            src={ReportMockup}
            alt="TGIC Report"
            className="md:-mt-40 -mt-36"
          />
        </div>
        <div className="md:w-8/12 md:pl-10">
          <h2>
            Gain insight into how we democratized investment opportunities for
            African{" "}
            <span className="text-torq-200">
              millennials over the past 3 years
            </span>
          </h2>

          <div className="mt-12">
            <ListItem text="Impact of financial education" />

            <ListItem text="Portfolio diversification in the face of the pandemic" />
            <ListItem text="Growth stories of companies we invested in and more" />
            <ListItem
              icon={false}
              text={
                <a
                  href={report_document[0].node.file.file.url}
                  target="_blank"
                  rel="noreferrer"
                  className="outline-none mt-8 block"
                >
                  <Button text="VIEW REPORT" />
                </a>
              }
            />
          </div>
        </div>
      </section>
    </section>
  )
}

TGICReport.propTypes = {}

export default TGICReport
