import React from 'react'
import Offering from './offering'
import {Button} from "../../utils"
import {Link} from "gatsby"
import Zoom from 'react-reveal/Zoom'

export const Offerings = () => {
    return(
        <Zoom out>

        <div className="bg-yellow-100 rounded-md max-w-screen-lg w-10/12 mx-auto pt-16 pb-10 shadow mb-32">
            <section className="w-10/12 mx-auto flex flex-wrap justify-between">
                <Offering text="Learn about investing money through our video courses" />
                <Offering text="Receive market information and opportunities in your email every week" />
                <Offering text="Gain insights from industry experts in our webinars and events" />
                {/* <Offering text="Access and track your investments on Twelvevest" /> */}
                <Offering text="Network within a community of ambitious people like you" />

                {/* Yes I need this Button */}
                <div className="sm:w-5/12  w-full flex space-x-2 mb-8">
                    <div className="w-1/12 hidden sm:block pt-2"></div>
                    <div className="w-11/12"><Link to="/join-us" className="outline-none"><Button text="YES, I NEED THESE" /></Link></div>
                </div>
            </section>
  
        </div>
        </Zoom>
    )
}
