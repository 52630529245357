import React from 'react'
import ListIcon from "../../../images/icons/list-item.svg"


const Offering = ({text}) => {
    return(
        

        <div className="sm:w-5/12  w-full flex space-x-2 mb-8">
            <div className="w-1/12 pt-2"><img src={ListIcon} alt=""/></div>
            <div className="w-11/12">{text}</div>
        </div>
       
    )
}

export default Offering