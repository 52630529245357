import React from 'react'
import Countup from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const Milestone = ({prefix, suffix, figure, description, hide}) => {
    const checkDecimal = figure % 1
    return(
        <div className={(hide ? "sm:block hidden ":" ") + "sm:w-5/12 w-8/12 mx-auto sm:mx-0  text-center sm:text-left mb-10"}>
            <h1 className="text-torq-200 mb-2 ">
            <Countup prefix={prefix} start={0}  end={figure}  suffix={`+ ${suffix}`} decimal={checkDecimal !== 0 ? ".": ""} decimals={checkDecimal !== 0 ? 1:0} >
                {({countUpRef, start}) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef}></span>
                    </VisibilitySensor>
                )}
            </Countup>
                
            </h1>
            <p className="sm:w-8/12 w-full">{description}</p>
        </div>
    )
}

export default Milestone