import React from 'react'
import Feature from './feature'

//Logos
import BBC from "../../../images/logo/bbc-africa.png"
import Vanguard from "../../../images/logo/vanguard.png"
import Nairametrics from "../../../images/logo/nairametrics.png"
import Businessday from "../../../images/logo/business-day.png"

import Fade from 'react-reveal/Fade'

export const Features = () => {return (
    <section className="mx-auto max-w-screen-lg my-24 text-center">
        <Fade bottom >
            <h3 className="uppercase font-display text-torq-400 text-normal sm:text-xl text-base sm:tracking-widest tracking-wider">As Featured On</h3>
            <article className="flex sm:flex-row flex-col justify-between mt-12 items-center">
                <Feature 
                url="https://www.vanguardngr.com/2020/02/tomie-balogun-creating-wealth-through-investment-club/" 
                image={Vanguard}
                brand="Vanguard"
                />
                <Feature 
                url="https://businessday.ng/enterpreneur/article/tomie-baloguns-investment-journey-and-how-you-can-benefit-from-it/" 
                image={Businessday}
                brand="Businessday"
                />
                <Feature 
                url="" 
                image={BBC}
                brand="BBC Africa"
                />
                <Feature 
                url="https://nairametrics.com/2020/07/08/the-impact-story-of-nigerias-biggest-investment-club-the-green-investment-club/" 
                image={Nairametrics}
                brand="Nairametrics"
                />
            </article>
        </Fade>
    </section>
)}