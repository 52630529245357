import React from 'react'
import {Button} from '../../components/utils'
import {Link} from 'gatsby'
import Fade from 'react-reveal/Fade'

export const Intro = () => {
    return (
        <section className="bg-torq-100 sm:text-center bg-no-repeat sm:bg-bottom bg-bottom-sm sm:bg-40 bg-90 bg-home-bg sm:pt-0 pt-4  px-12  ">
            <div className="max-w-screen-xl mx-auto flex min-h-80  sm:flex-row flex-col sm:items-center items-start">
            <div className="sm:w-1/12"></div>

            {/* Intro Image */}
            <div className="sm:w-5/12 w-full     "></div>

            {/* Intro Text */}
            <Fade bottom cascade>
            <div className="sm:w-6/12 w-full text-left  ">
                
                <h1 className="text-sm">Wealth is 75% information, 25% action. Welcome to <br/><span className="text-torq-200">the full package.</span></h1>
                <p className="sm:w-9/12 w-full">Join the biggest investment club in Nigeria and get lifetime, first-hand access to  financial education, opportunities and the support you need to live the life you want.</p>
                <Link to="/join-us"><Button text="BECOME A MEMBER" link="/join-us"/></Link>
            
                
            </div>
            </Fade>
            </div>
            
        </section>
    )
}

