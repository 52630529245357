import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Milestone from './milestone'

export const Milestones = () => {
    const milestones = useStaticQuery(graphql `
    query MyQuery {
        allContentfulFigures(sort: {fields: createdAt, order: ASC}) {
          edges {
            node {
              figure
              description
              suffix
              prefix
            }
          }
        }
      }`)

    const milestones_show = milestones.allContentfulFigures.edges.map(milestone => 
        <Milestone 
        prefix = {(milestone.node.prefix == null ? "":milestone.node.prefix) }
        suffix = {(milestone.node.suffix == null ? "": milestone.node.suffix)}
        figure={ milestone.node.figure } description={milestone.node.description}/> 
    )
    return(
        <div className="px-8 pt-32 pb-16 max-w-screen-lg m-auto flex justify-between flex-wrap  items-start">
          {milestones_show}
        </div>
    )
}