import React from "react"
import { Button } from "../utils"
import Growth from "../../images/growth.svg"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"

export const Subfooter = () => {
  const prices = useStaticQuery(
    graphql`
      query {
        allContentfulPricing(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              price
            }
          }
        }
      }
    `
  )

  // Get the price for house member
  const house_member_price = prices.allContentfulPricing.edges[0].node.price
  const black_member_price = prices.allContentfulPricing.edges[2].node.price
  return (
    <section className="text-center bg-torq-100 sm:pt-32 pt-16 sm:min-h-screen px-10 ">
      <Fade bottom cascade>
        <div className="max-w-screen-lg mx-auto mb-32 ">
          <h2>
            Our free investment <br className="sm:hidden" />
            advice for you right now?
            <br />
            <span className="text-torq-200">
              Sign up with a lifetime membership fee of ${black_member_price}
            </span>
          </h2>
          <p className="sm:w-8/12 w-full mx-auto">
            Not ready to take that big bold step yet? You can start from
            somewhere. With flexible plans starting from ${house_member_price},
            you can begin your investment journey. We also have offers for
            groups of friends or mini investment clubs who want to join
            collectively.
          </p>
          <Link to="/join-us#packages">
            <Fade bottom>
              <Button text="Membership Plans" />
            </Fade>
          </Link>
        </div>
      </Fade>
      <Fade big>
        <img src={Growth} alt="Tree of Weatlh" className="block mb-0 mx-auto" />
      </Fade>
    </section>
  )
}
